import React from "react";
import "../DIPSAR/Dipsar.css";

import RameshGoyal from "./SPS Faculty/01-Prof. Ramesh K. Goyal/download-46.png";
import MukeshNandave from "./SPS Faculty/02-Dr. Mukesh Nandave/WhatsApp-Image-2023-05-25-at-6.26.36-PM-1-e1685080004876.jpeg";
import AjitThakur from "./SPS Faculty/03-Dr. Ajit Kumar Thakur/Ajit-Web2-1831x2048.jpg";
import DrAparnaChaudhary from "./SPS Faculty/04- Dr. Aparna Chaudhary/Pic-aparna-ORIGINAL-Aparna-Chaudhary-e1686633545144.jpg";

// Department of Pharmaceutics/ Industrial Pharmacy/ Cosmeceutics/ DRA
import HarvinderP from "./SPS Faculty/05-Dr. Harvinder Popli/Picture1-e1686033825457.jpg";
import GeetaAggarwal from "./SPS Faculty/06-Prof. Geeta Aggarwal/Picture3-e1686040434582.jpg";
import SushamaT from "./SPS Faculty/07-Dr. Sushma Talegaonkar/Picture2.jpg";
import MadhuGupta from "./SPS Faculty/08-Dr. Madhu Gupta/IMG_8991-1-2048x1365.jpg";
import MinakshiGarg from "./SPS Faculty/09-Dr. Minakshi Garg/Picture44.jpg";
import AmanpreetKaur from "./SPS Faculty/10-Dr. Amanpreet Kaur/WhatsApp-Image-2022-07-15-at-2.42.38-PM.jpeg";
import SumitSharma from "./SPS Faculty/11-Dr. Sumit Sharma/IMG_8973-2048x1365.jpg";
import BhartiMangla from "./SPS Faculty/12-Dr. Bharti Mangla/Picture6.jpg";

// Department of Pharmaceutical Chemistry/ Pharmaceutical Analysis
import RajivTonk from "./SPS Faculty/13-Prof. Rajiv Tonk/IMG_6334-scaled-e1685079586344.jpg";
import PrabodhSharma from "./SPS Faculty/14-Dr. Prabodh Chander Sharma/WhatsApp-Image-2023-07-08-at-11.21.37-AM.jpeg";
import SurajPal from "./SPS Faculty/16-Dr. Suraj Pal Verma/IMG_6324-scaled-e1685079778102.jpg";

import PritiJain from "./SPS Faculty/15-Dr. Priti Jain/IMG_6327-scaled-e1685079719758.jpg";
import preaveenKumar from './SPS Faculty/17-Dr. K. Praveen Kumar/IMG_6330-scaled-e1685079895818-150x150.jpg'
import KalicharanS from "./SPS Faculty/18-Dr. Kalicharan Sharma/IMG_6159-scaled-e1684834129110-150x150.jpg";

// Department of Pharmacognosy and Phytochemistry
import AjaySharma from "./SPS Faculty/19-Prof. Ajay Sharma/AJAY-sir.jpg";
import MahaveerDhobi from "./SPS Faculty/20-Dr. Mahaveer Dhobi/IMG_6146-scaled-e1685951739293.jpg";
import VijenderKumar from "./SPS Faculty/21-Dr. Vijender Kumar/IMG_6133-scaled-e1684305618834.jpg";
import DrAryaM from "./SPS Faculty/22-Dr. Arya Lakshmi Marisetti/IMG_6134-scaled-e1684305530426.jpg";
import DeveshTewari from "./SPS Faculty/23-Dr. Devesh Tewari/IMG_6129-scaled-e1684305291886.jpg";

// Department of B. Pharm (Ayurveda)
// import FoziyahZakir from "./PhramaScienceFiles/FoziyahZakir.jpg";
// import AmritaMishra from "./PhramaScienceFiles/AmritaMishra.jpeg";
// import SaveenaChauhan from "./PhramaScienceFiles/SaveenaChauhan.jpeg";
// import RoshniTandey from "./PhramaScienceFiles/RoshniTandey.jpeg";

//Other Faculties
import DrHimanshu from "./SPS Faculty/24-Dr. Himanshu/IMG_1997-scaled-e1688796428648.jpg";
import VarunSharma from "./SPS Faculty/25-Dr. Varun Sharma/WhatsApp-Image-2022-11-14-at-4.16.35-PM-pxp3oes3a0oho86eyhry4lfigk3k1pqzfr2cb9i3j0.jpeg";
import newvcmaheshverma from './SPS Faculty/vc-sir-rounded-modified.e9cbe38f6ea4988c48dd.png'


import { NavLink } from "react-router-dom";

// activities files
import oldActivity01 from './PhramaScienceFiles/Brain-Storming-Session-on-DPSRU-NIB-Certificate-Program-on-13.08.2021.pdf'
import oldActivity02 from './PhramaScienceFiles/Revised-DPSRU_AIIA-MoU_Facility-Inauguration.pdf'
import oldActivity03 from './PhramaScienceFiles/WORLD-PHARMACIST-DAY-25-September2020.pdf'
import oldActivity04 from './PhramaScienceFiles/Webinar-on-Quality-by-Design-and-its-Role-and-Application-in-the-Pharmaceutical-Field-on-06.08.2021-updated.pdf'
import oldActivity05 from './PhramaScienceFiles/Healthy-Heart-Campaign-was-organised-by-Delhi-Pharmaceutical-Sciences-and-Research-University-DPSRU.pdf'
import oldActivity06 from './PhramaScienceFiles/ICCS-2020-report-for-DRDO.pdf'
import oldActivity07 from './PhramaScienceFiles/MoU-write-up-DPSRU-and-THSTI.pdf'
import oldActivity08 from './PhramaScienceFiles/Report-Prof-SK-Gupta-Oration-2022.pdf'
import oldActivity09 from './PhramaScienceFiles/REPORT-rt-PCR-18.9.19.pdf'

import oldActivity10 from './PhramaScienceFiles/Webinar-on-Precision-Medicine-Health-Care-of-the-21stCentury.pdf'
import oldActivity11 from './PhramaScienceFiles/Webinar-on-Drug-Solubilization-Using-Hot-Melt-Extrusion-on-20.08.2021.pdf'
import oldActivity12 from './PhramaScienceFiles/Webinar-on-Colorcon-Coating-Techniques-with-virtual-laboratory-demonstration-updated.pdf'
import oldActivity13 from './PhramaScienceFiles/VIRTUAL-LAB-TOUR-OF-ACCUPREC-RESEARCH-LABS-PVT.-LTD.-J-AHMEDABAD-on-19.06.2021-updated.pdf'
import oldActivity14 from './PhramaScienceFiles/One-day-webinar-on-Mass-Spectrometry-and-Liquid-Chromatographic-Separation-2021-updated.pdf'
import oldActivity15 from './PhramaScienceFiles/Learn-With-the-Experts-A-Webinar-on-Flow-Cytometry-updated.pdf'
import oldActivity16 from './PhramaScienceFiles/LAUNCH-OF-HERBAL-FORMULATION-AND-SUCCESS-IN-CLINICAL-TRIALS-FOR-COVID-updated.pdf'
import oldActivity17 from './PhramaScienceFiles/JAMMI-AT-YAMUNA-BIODIVERSITY-FOR-EXPLORATION-WITH-DPSRU-on-15-16th-December-2019-updated.pdf'
import oldActivity18 from './PhramaScienceFiles/Hands-on-Training-Workshop-on-Digital-Imaging-Microscopy-and-Application-on-02.02.2021.pdf'
import oldActivity19 from './PhramaScienceFiles/Guest-lecture-on-Toxicophoroes-in-Drug-Discovery-on-24.02.2021-updaed.pdf'
import oldActivity20 from './PhramaScienceFiles/Expert-talk-on-Regulatory-Affairs-Scope-Challenges-and-Futuristic-Scenario-in-Covid-time-on-23.06.2021.pdf'
import oldActivity21 from './PhramaScienceFiles/Expert-talk-on-Overview-of-Indian-Medical-Device-Rules-published-by-CDSCO-17.08.2021-updated.pdf'
import oldActivity22 from './PhramaScienceFiles/DPSRU-CCRUM-MoU-Signing-Ceremony.pdf'

function SchoolOfPharmaceuticalSciences() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        School of Pharmaceutical Sciences
      </h2>

     
      
      <h2 className="dipsar-sub-heading">Programmes</h2>
      <h3 className="dpsru-secondary-heading-for-every-page">
        Undergraduate Programs
      </h3>
      <div className="undergradute-program-box">
        <ul>
          <li>B Pharm</li>
          <li> B Pharm Ayurveda</li>
        </ul>
      </div>
      <h3 className="dpsru-secondary-heading-for-every-page">
        Post Graduate Programs
      </h3>
      <div className="post-graduation-box">
        <ul style={{ lineHeight: "30px", fontSize: "1.3rem" }}>
          <li>M Pharm (Cosmeceutics)</li>
          <li>M Pharm (Pharmacognosy)</li>
          <li>M Pharm (Pharmaceutical Analysis)</li>
          <li>M Pharm (Industrial Pharmacy)</li>
          <li>M Pharm (Pharmaceutical Biotechnology)</li>
          <li>M Pharm (Drug Regulatory Affairs)</li>
          <li>M Pharm (Executive Drug Regulatory Affairs)</li>
          <li>M Pharm (Clinical Research)</li>
          <li>M Pharm (Plant Tissue Culture)</li>
        </ul>
      </div>
      {/* Faculty details */}
      <div className="faculty-details-box">
        <h2 className="dipsar-sub-heading">Faculty Details</h2> <hr />
        <h2 className="dpsru-secondary-heading-for-every-page">
          Department of Pharmacology/ Biotechnology
        </h2>
      </div>
      {/*  faculty box */}
      {/* each faculty Box */}
      <NavLink
        // to="/Prof-Ramesh-kumar-goyal"
        className="each-faculty-box-navlink-container"
      >
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={newvcmaheshverma} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Prof. (Dr.) Mahesh Verma</h3>
            <h4 className="faculty-position">
              Honourable Vice- Chancellor 
            </h4>
          </div>
        </div>
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Mukesh-Nandave"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={MukeshNandave} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Mukesh Nandave</h3>
          <h4 className="faculty-position">Associate Dean (Research & Consultancy) <br/>
Head, Department of Pharmacology</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Ajit-kumar-thakur"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={AjitThakur} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Ajit K. Thakur</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div> </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Aparna-chaudhary"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={DrAparnaChaudhary} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">
Dr. Aparna Chaudhary</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div> </NavLink>{" "}
      <hr />
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmaceutics/ Industrial Pharmacy/ Cosmeceutics/ DRA
      </h2>{" "}
      <hr />


      {/* each faculty Box */}
      <NavLink
        to="/Dr-Harvinder-Popli"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={HarvinderP} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Harvinder Popli</h3>
          <h4 className="faculty-position">Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Prof-Geeta-Aggarwal"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={GeetaAggarwal} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Prof. Geeta Aggarwal</h3>
          <h4 className="faculty-position">Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-sushma-telegaonkar"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={SushamaT} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sushma Talegaonkar</h3>
          <h4 className="faculty-position">Associate Professor</h4>
        </div>
      </div>
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Madhu-gupta"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={MadhuGupta} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Madhu Gupta</h3>
          <h4 className="faculty-position">Associate Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Minakshi-garg"
        className="each-faculty-box-navlink-container"
      >
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={MinakshiGarg} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Minakshi Garg</h3>
          <h4 className="faculty-position">Associate Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Amanpreet-kaur"
        className="each-faculty-box-navlink-container"
      >     <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={AmanpreetKaur} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Amanpreet Kaur</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div></NavLink>
 {" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Sumit-Sharma"
        className="each-faculty-box-navlink-container"
      >  
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={SumitSharma} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Sumit Sharma</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div> </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Bharti-mangla"
        className="each-faculty-box-navlink-container"
      >  
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={BhartiMangla} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Bharti Mangla</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div> </NavLink>{" "}
      <hr />
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmaceutical Chemistry/ Pharmaceutical Analysis
      </h2>
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Rajiv-tonk"
        className="each-faculty-box-navlink-container"
      >  
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={RajivTonk} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Prof. Rajiv Tonk</h3>
          <h4 className="faculty-position"> Professor</h4>
        </div>
      </div>
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Prabodh-Chander-Sharma"
        className="each-faculty-box-navlink-container"
      >  
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={PrabodhSharma} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Prabodh Chander Sharma</h3>
          <h4 className="faculty-position">Associate Dean (Academics)</h4>
        </div>
      </div>
      </NavLink>
      <hr />
       {/* each faculty Box */}
       <NavLink
        to="/Dr-Priti-jain"
        className="each-faculty-box-navlink-container"
      > 
       <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={PritiJain} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Priti Jain</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div></NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Suraj-pal-verma"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={SurajPal} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Suraj Pal Verma</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-k-Praveen-Kumar"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={preaveenKumar} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. K. Praveen Kumar</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
     
      {/* each faculty Box */}
      {/* <NavLink
        to="/Dr-kali-charan-sharma"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={KalicharanS} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Kalicharan Sharma</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink> */}
      <hr />
      <h2 className="dpsru-secondary-heading-for-every-page">
        Department of Pharmacognosy and Phytochemistry
      </h2>
      {/* each faculty Box */}
      <NavLink
        to="/Prof-Ajay-Sharma"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={AjaySharma} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Prof. Ajay Sharma</h3>
          <h4 className="faculty-position">Professor</h4>
        </div>
      </div>
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Mahaveer-dhobi"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={MahaveerDhobi} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Mahaveer Dhobi</h3>
          <h4 className="faculty-position">Associate Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Vijender-kumar"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={VijenderKumar} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Vijender Kumar</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Arya-Lakshmi-Marisetti"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={DrAryaM} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Arya Lakshmi Marisetti</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      </NavLink>
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Devesh-tewari"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={DeveshTewari} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Devesh Tewari</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink>
      <hr />
      {/* <h2 className="dpsru-secondary-heading-for-every-page">
        Department of B. Pharm (Ayurveda)
      </h2>{" "} */}
      <hr />

      {/* Department Of B. Pharm (Ayurveda) */}
      {/* each faculty Box */}
      
          {/* <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={FoziyahZakir} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Foziyah Zakir</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      <hr /> */}

      {/* each faculty Box */}

      {/* <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={AmritaMishra} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Amrita Mishra</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      <hr /> */}

      {/* each faculty Box */}
      {/* <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={SaveenaChauhan} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Saveena Chauhan</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      <hr /> */}

      {/* each faculty Box */}

      {/* <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={RoshniTandey} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Roshni Tandey</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>{" "}
      <hr /> */}
      
      <h2 className="dpsru-secondary-heading-for-every-page">
        Other Faculties
      </h2>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Himanshu-Assistant-Professor"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={DrHimanshu} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Himanshu</h3>
          <h4 className="faculty-position">Assistant Professor</h4>
        </div>
      </div>
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink
        to="/Dr-Varun-sharma"
        className="each-faculty-box-navlink-container"
      > 
      <div className="each-faculty-box">
        <div className="each-faculty-img">
          <img src={VarunSharma} alt="faculty" />
        </div>
        <div className="each-faculty-name-and-post">
          <h3 className="faculty-name">Dr. Varun Sharma</h3>
          <h4 className="faculty-position">
            Assistant Professor of Communications
          </h4>
        </div>
      </div>
      </NavLink>
      <hr />
      <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities</h2>

        <ul>
          <li>
            <a href={oldActivity01}>
            Brain Storming Session on DPSRU-NIB Certificate Program on Quality Control of Biopharmaceuticals
            </a>
          </li>
          <li>
            <a href={oldActivity02}>
            DPSRU Signs MoU with All India Institute of Ayurveda
            </a>
          </li>
          <li>
            <a href={oldActivity03}>WORLD PHARMACIST DAY 25 September 2020</a>
          </li>
          <li>
            <a href={oldActivity04}>Webinar on Quality by Design and its Role and Application in the Pharmaceutical Field on 06.08.2021</a>
          </li>
          <li>
            <a href={oldActivity05}>Healthy Heart Campaign was organised by Delhi Pharmaceutical Sciences and Research University (DPSRU)</a>
          </li>
         
          <li>
            <a href={oldActivity06}>ICCS-2020 report for DRDO</a>
          </li>
          <li>
            <a href={oldActivity07}>
            MoU write up DPSRU and THSTI
            </a>
          </li>
          <li>
            <a href={oldActivity08}>
            Report- Prof SK Gupta Oration 2022
            </a>
          </li>
          <li>
            <a href={oldActivity09}>
            Hands-On Training And Demonstration Of Real Time-Polymerase Chain Reaction On 17th September, 2019
            </a>
          </li>
          <li>
            <a href={oldActivity10}>
            Webinar on Precision Medicine Health & Care of the 21stCentury on 13.08.2021
            </a>
          </li>
          <li>
            <a href={oldActivity11}>
            Webinar on Drug Solubilization Using Hot Melt Extrusion on 20.08.2021
            </a>
          </li>
          <li>
            <a href={oldActivity12}>
            Webinar on “Colorcon Coating Techniques with virtual laboratory demonstration” on 06.09.2021
            </a>
          </li>
          <li>
            <a href={oldActivity13}>
            Virtual Lab Tour Of Accuprec Research Labs Pvt. Ltd., Ahmedabad On 19.06.2021
            </a>
          </li>
          <li>
            <a href={oldActivity14}>
            One-day webinar on Mass Spectrometry and Liquid Chromatographic Separation 2021
            </a>
          </li>
          <li>
            <a href={oldActivity15}>
            “Learn With the Experts: A Webinar on Flow Cytometry”
            </a>
          </li>
          <li>
            <a href={oldActivity16}>
            Launch Of Herbal Formulation And Success In Clinical Trials For Covid-19: Coroquil-Zn
            </a>
          </li>
          <li>
            <a href={oldActivity17}>
            Jammi At Yamuna Biodiversity For Exploration With DPSRU on 15-16th December, 2019
            </a>
          </li>
          <li>
            <a href={oldActivity18}>
            Hands on Training Workshop on Digital Imaging Microscopy and Application on 02.02.2021
            </a>
          </li>
          <li>
            <a href={oldActivity19}>
            Guest lecture on “Toxicophoroes in Drug Discovery” on 24.02.2021
            </a>
          </li>
          <li>
            <a href={oldActivity20}>
            Expert talk on “Regulatory Affairs: Scope, Challenges and Futuristic Scenario in Covid time” on 23.06.2021
            </a>
          </li>
          <li>
            <a href={oldActivity21}>
            Expert talk on “Overview of Indian Medical Device Rules, published by CDSCO” on 17.08.2021
            </a>
          </li>
          <li>
            <a href={oldActivity22}>
            DPSRU-CCRUM MoU Signing Ceremony
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SchoolOfPharmaceuticalSciences;
