import React from 'react'
//  import registrarMsg from './PopliMadam.245f27493e38cdeeb86d.245f27493e38cdeeb86d.jpg'
 import registrarMsg from '../../Home/HomePageData/profPKSahooDirector.jpeg'
// import chancellorSign from './chancellorSign.jpg'
// import chancellorMsg from './chancellorMsg.jpg'
import '../ViceChancellor.css'

function OldRegistrarTill30Jan2025() {
    return (
        <div className='vice-chancellor-full-component'>
            <h2 className="vc-comp-heading">
            From the Registrar's Desk
            </h2>

            <div className="chancellor-and-vice-chancellor-image-container">
                <img src={registrarMsg} alt="VICE CHANCELLOR" className="vc-img" />
            </div>
            <div className="vc-intro">
                <h3 className="vc-intro-detailes-text">
                Prof. P.K. Sahoo  <br />
                Offg. Registrar (DPSRU) & Director (DIPSAR)<br />
                </h3>
                {/* <h3 className="vc-intro-contact"> */}
                    {/* Ph. (Off.): +91-11-23960809<br />
                    Ph. (Off.): +91-11-23975022<br />
                    Fax	:+91-11-23937099 <br /> */}
                {/* </h3> */}
            </div>

            <div className="vc-message-full">
            Delhi Pharmaceutical Sciences and Research University (DPSRU) is offering UG and PG education in the field of Pharmacy, Allied health and Physiotherapy. All the courses are approved by Pharmacy Council of India (PCI) & All India Council for Technical Education (AICTE). The University continuously strives to provide high quality professional education and research training to students with the help of their ever dedicated staff to build one of the best educational setups. <br />
DPSRU is extending education to produce independent and skilled pharmacists/Health care professionals/Physiotherapist on a global scale. The University encourages students for the professional growth and dedication to high-quality, by educating them with key values like honesty, reliability, intelligence, incentive, collaboration, and dedication.  <br />
DPSRU providing world-class pharmaceutical training and research to meet the health care needs of communities and technology-driven industries.





            </div> 

           <div className="sign-container chancellor-sign-container-img">
                {/* <img src={chancellorSign} alt="vc-signature" className="vc-sign" /> */}
                <p className="vc-sign-text" style={{ fontFamily: 'cursive' }}>With warmest regards , <br />
                Prof. P.K. Sahoo
                </p>


            </div> 

            {/* <div className="original-copy-of-vc-and-chancellor-msg">
                <a href={chancellorMsg}> */}
                    {/* <img src={chancellorMsg} alt="Chancellor Message" /> */}
                    {/* See the original Copy of Chancellor Message
                </a>
            </div> */}
            <br /><br />

        </div>



    )
}

export default OldRegistrarTill30Jan2025