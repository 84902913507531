import React from "react";
//  import registrarMsg from './PopliMadam.245f27493e38cdeeb86d.245f27493e38cdeeb86d.jpg'
// import registrarMsg from "../../Home/HomePageData/profPKSahooDirector.jpeg";
 import registrarMsg from "../../Staff/Staff Data/Administrative Staff/khatiSir.jpg";
// import chancellorSign from './chancellorSign.jpg'
// import chancellorMsg from './chancellorMsg.jpg'
import "../ViceChancellor.css";

function RegistrarOfDpsru() {
  return (
    <div className="vice-chancellor-full-component">
      <h2 className="vc-comp-heading">From the Registrar's Desk</h2>

      <div className="chancellor-and-vice-chancellor-image-container">
        <img src={registrarMsg} alt="VICE CHANCELLOR" className="vc-img" />
      </div>
      <div className="vc-intro">
        <h3 className="vc-intro-detailes-text">
          Dr. Ramchander Khatri, <br />
          Offg. Registrar (DPSRU) & Asst. Controller of Examination
          <br />
        </h3>
        {/* <h3 className="vc-intro-contact"> */}
        {/* Ph. (Off.): +91-11-23960809<br />
                    Ph. (Off.): +91-11-23975022<br />
                    Fax	:+91-11-23937099 <br /> */}
        {/* </h3> */}
      </div>

      <div className="vc-message-full">
      Welcome to Delhi Pharmaceutical Sciences and Research University (DPSRU), a Premier Institution dedicated to excellence in Pharmaceutical Education, Research, and Innovation. As the Registrar, it is my privilege to be part of an academic ecosystem that nurtures future leaders in Healthcare, Life Sciences, and allied fields. <br /><br />

At DPSRU, we are committed to upholding the highest standards of academic Integrity, administrative efficiency, and student-centric policies. Our focus is on streamlining processes, fostering a transparent governance system, and ensuring a seamless experience for students, faculty, and stakeholders. From Admissions to Examinations, Research support to student welfare, we continuously strive to enhance the academic journey of our learners.  <br /><br />

With an evolving global landscape in Pharmaceutical Sciences, we encourage interdisciplinary research, industry collaborations, and innovation-driven education. We take pride in our state-of-the-art infrastructure, distinguished faculty, and a dynamic student community that contributes to the advancement of healthcare and medicine.  <br /><br />

We welcome all Students, Researchers, and Professionals to engage with DPSRU, explore opportunities, and contribute to our vision of academic and research excellence. Let us work together to shape a future where knowledge and innovation drive societal progress. <br /><br />
      </div>

      <div className="sign-container chancellor-sign-container-img">
        {/* <img src={chancellorSign} alt="vc-signature" className="vc-sign" /> */}
        <p className="vc-sign-text" >
          With warmest regards  <br />
          Dr. Ramchander Khatri

        </p>
      </div>

      {/* <div className="original-copy-of-vc-and-chancellor-msg">
                <a href={chancellorMsg}> */}
      {/* <img src={chancellorMsg} alt="Chancellor Message" /> */}
      {/* See the original Copy of Chancellor Message
                </a>
            </div> */}
      <br />
      <br />
    </div>
  );
}

export default RegistrarOfDpsru;
