import React from "react";
import "./AuthoritiesoftheUniversity.css";

const AuthoritiesoftheUniversity = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Authorities of the University
      </h2>

      <h2 className="dpsru-secondary-heading-for-every-page">
        General Council
      </h2>
      <div className="authority-of-university-g-c-container">
        <ol>
          <li>
            Shri Vinai K. Saxena,Hon’ble Lt. Governor of Delhi & Chancellor of
            DPSRU, New Delhi
            <span> - Chairman</span>
          </li>
          <li>
            Dr. Jitendra N. Verma, Chief Scientific Officer & Managing Director
            Lifecare Innovations Pvt. Ltd. <span> - Member</span>
          </li>
          <li>
            Prof. Ravichandiran V. -Vice Chancellor, DPSRU <span> - Member</span>
          </li>
          <li>
          Prof. P.K. Sahoo, Director, Delhi Institute of Pharmaceutical Sciences And Research,  New Delhi
            <span> - Non Member Secretary & VC Nominee</span>
          </li>

          <li>
          Dr. Ramchander Khatri, Offg. Registrar (DPSRU) & Asst. Controller of Examination,  New Delhi
            <span> - Non Member Secretary & VC Nominee</span>
          </li>
          <li>
          Ms. Nandini Paliwal, IAS, Secretary, DTTE, GNCTD <span> - Member</span>
          </li>
          <li>
            Dr. A.C. Verma, IAS, Principal Secretary, Finance, GNCTD
            <span> - Member</span>
          </li>

          <li>
            Three persons of eminence nominated by the Chancellor :
            <ol style={{ margin: "1% 2%" }}>
              <li>
                Dr. C.R. Babu, Professor Emeritus and former Pro-VC, Delhi
                University
              </li>
              <li>
                Shri Rajeev Singh Raghuvanshi, Secretary cum Scientific
                Director, Indian Pharmacopoeia Commission, Ministry of Health &
                Family Welfare, Govt. of India
              </li>
              <li>
                Shri Pankaj Ramanbhai Patel, Chairman & Managing Director,
                Cadila Healthcare Ltd.
              </li>
            </ol>
          </li>

          {/* <li>
          Prof. Harvinder Popli, Offg. Registrar, Dean R & I, International Affair
            <span> - Non-Member Secretary</span>
          </li> */}
        </ol>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Board of Governors
      </h2>
      <div className="authority-of-university-g-c-container">
        <ol>
          <li>
            Dr. Jitendra N. Verma, Managing Director, Lifecare Innovations Pvt.
            Ltd.
            <span> - Chairman</span>
          </li>
          <li>
            Prof. Ravichandiran V. -Vice Chancellor, DPSRU <span> - Member</span>
          </li>
          <li>
            Dr. A.C. Verma, IAS, Principal Secretary, Finance, GNCTD
            <span> - Member</span>
          </li>
          <li>
          Ms. Nandini Paliwal, IAS, Secretary, Directorate of Training And
            Technical Education, GNCTD
            <span> - Member</span>
          </li>
          <li>
            Dr. Navin K. Patel, senior ENT & Head and Neck surgeon Lifecate
            Hospital
            <span> - Member</span>
          </li>
          <li>
            Mr. Abhimanyu Singh Rana,Founder Big Band Food Tech.
            <span> - Member</span>
          </li>
          <li>
            Dr. (Mrs) Urvashi B. Singh, Professor, Microbiology, A.I.I.M.S., New
            Delhi <span> - Ex-officio Member</span>
          </li>
          <li>
            Dr. Mayank Mathur, Sr. Principal Scientist, CSIR
            <span> - Member</span>
          </li>
          {/* <li>
          Prof. Harvinder Popli, Offg. Registrar, Dean R & I, International Affairs DPSRU
            <span> - Non-Member Secretary</span>
          </li> */}
          <li>
          Prof. P.K. Sahoo, Director, Delhi Institute of Pharmaceutical Sciences And Research <span> - VC Nominee</span>
          </li>
          <li>
          Dr. Ramchander Khatri, Offg. Registrar (DPSRU) & Asst. Controller of Examination <span> - VC Nominee</span>
          </li>
          <li>
            Prof. Deepti Pandita, Prof. Pharmaceutics DISPAR
            <span> - VC Nominee</span>
          </li>
        </ol>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Academic Council
      </h2>
      <div className="authority-of-university-g-c-container">
        <ol>
          <li>
            Prof. Ravichandiran V. , Vice Chancellor, Delhi Pharmaceutical Science
            And Research University, New Delhi
            <span> - Chairman</span>
          </li>

          <li>
            Prof. Surinder Singh,, Former Vice Chancellor, Jagadguru Sri
            Shivarathreeshwara University, UP
            <span> - Member</span>
          </li>

          <li>
            Prof. N. Udupa, Professor, Manipal College of Pharmaceutical
            Sciences, Karnataka
            <span> - Member</span>
          </li>

          <li>
            Dr. Saroj Chooramani Gopal, Former President, National Academy Of
            Medical Sciences, New Delhi
            <span> - Member</span>
          </li>

          <li>
            Dr. Rajiv Gulati, Independent Non-Executive Director, Vazir Sultan
            Tobacco Company Limited, Delhi
            <span> - Member</span>
          </li>

          <li>
            Prof. P.K. Sahoo, Director, Delhi Institute of Pharmaceutical
            Sciences And Research, DPSRU, New Delhi
            <span> -Non Member Secretary & VC Nominee</span>
          </li>

          <li>
             Dean Student Welfare, Delhi Pharmaceutical
            Sciences And Research University, New Delhi
            <span> - Ex-officio Member</span>
          </li>

          <li>
             Dean (Academics), Delhi Pharmaceutical
            Sciences And Research University, New Delhi
            <span> - Ex-officio Member</span>
          </li>

          <li>
            Head of Department, Physiotherapy, Delhi
            Pharmaceutical Sciences And Research University, New Delhi
            <span> - Ex-officio Member</span>
          </li>

          <li>
             HOD, School of Allied Sciences, DPSRU
            <span> - Ex-officio Member</span>
          </li>

          <li>
            Prof. S. R. Wakode, Professor, Department of Pharmaceutical
            Chemistry, Delhi Pharmaceutical Sciences And Research University
            <span> - Member (VC Nominee)</span>
          </li>

          <li>
             Delhi Pharmaceutical
            Sciences And Research University, New Delhi
            <span> - Member (VC Nominee)</span>
          </li>

          <li>
             Director , SOPS, DPSRU
            <span> - Member (VC Nominee)</span>
          </li>

          <li>
            Dr. Ramchander Khatri, Offg. Registrar (DPSRU) & Assistant Controller of Examination, Delhi
            Pharmaceutical Sciences And Research University, New Delhi
            <span> - Ex-officio Member</span>
          </li>

          {/* <li>
          Dr. Harvinder Popli, Registrar, Delhi Pharmaceutical Sciences And Research University, New Delhi    
            <span> - Non-Member Secretary</span>
          </li> */}
        </ol>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Finance Committee
      </h2>
      <div className="authority-of-university-g-c-container">
        <ol>
          <li>
            Prof. Ravichandiran V. , Vice Chancellor, Delhi Pharmaceutical Science
            And Research University, New Delhi
            <span> - Chairman</span>
          </li>

  <li style={{color:'#292929'}}>
            Sh. Rakesh Kumar Verma, Indian Audit and Account Service (Retd.)
            <span> - Member</span>
          </li>
          <li style={{color:'#292929'}}>
           Ms. Tanya Bundela, Social Worker
            <span> - Member</span>
          </li>
  

         
          {/* <li>
            Ms. Alice Vaz, IAS, Secretary, Directorate of Training And Technical
            Education, GNCTD
            <span> - Member</span>
          </li> */}
          {/* <li>
            Dr. Arun Kumar Agarwal, Former Dean, Maulana Azad Medical College,
            New Delhi
            <span> - Member</span>
          </li> */}
          {/* <li>
          Prof. P.K. Sahoo, Offg. Registrar (DPSRU) & Director (DIPSAR), Dean R & I, Delhi Pharmaceutical Sciences
            And Research University, New Delhi
            <span> - Member Secretary</span>
          </li> */}

          
  <li>
         The Principal Secretary, Finance, GNCTD
            <span> -Ex-Officio-Member</span>
          </li> 
  <li>
         The Principal Secretary / Secretary Technical Education , GNCTD
            <span> -Ex-Officio-Member</span>
          </li> 

      <li>
            Mr. Abhimanyu Singh Rana
            <span> - Member</span>
          </li>
          <li style={{color:'#292929'}}>
           Prof. Deepti Pandita, DPSRU
            <span> - Member</span>
          
 
</li>

          <li>
           Controller of Finance
            <span> - Ex-Officio-Member-Secretary</span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default AuthoritiesoftheUniversity;
